<template>
  <v-form ref="form" v-model="isValidForm" autocomplete="off" @submit.prevent="createMedia">
    <v-text-field v-model="media.name" label="Название" required :rules="[v => !!v || 'Не введено название']" />

    <v-text-field v-model="media.rss" label="RSS" />

    <v-row>
      <v-col cols="12" sm="6" lg="3" xl="3">
        <media-logo v-model="media.logo" :media="media" />
      </v-col>
    </v-row>

    <div class="mt-4">
      <v-btn type="submit" color="primary mr-4" :loading="loading">Добавить СМИ</v-btn>
    </div>
  </v-form>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";
import { encodeFileToBase64 } from "~/core/helpers";

import MediaLogo from "./logo";

const defaultCreateMediaDto = {
  name: "",
  rss: "",
  logo: null
};

Object.freeze(defaultCreateMediaDto);

export default {
  data() {
    return {
      loading: false,
      media: { ...defaultCreateMediaDto },

      isValidForm: true
    };
  },
  computed: {
    createMediaDto() {
      return { ...this.media };
    }
  },
  methods: {
    async createMedia() {
      this.loading = true;

      try {
        if (!this.isValidForm) {
          this.$refs.form.validate();

          return;
        }

        this.createMediaDto.logo = await encodeFileToBase64(this.createMediaDto.logo);

        await this.$axios.$post("news/rss", this.createMediaDto);
        await this.$parent.refreshTable();

        this.clear();

        this.$snackbar.success("СМИ создана");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.createMediaDto)).toHtml();

        this.$snackbar.error("Не удалось создать СМИ: " + html);
      } finally {
        this.loading = false;
      }
    },

    clear() {
      this.media = { ...defaultCreateMediaDto };

      this.$refs.form.resetValidation();
    }
  },
  components: {
    MediaLogo
  }
};
</script>
