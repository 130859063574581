<template>
  <div class="pa-3">
    <data-table ref="dataTable" url="news/rss" :headers="headers">
      <template v-slot:[`item.logo`]="{ item }">
        <div class="d-flex align-center py-2">
          <div>
            <v-img class="rounded-circle" :src="`${item.logo}?${imgKey}`" width="44" height="44" contain />
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item: media }">
        <nobr>
          <v-btn class="mr-2" icon color="accent" :to="{ name: 'media-name', params: { name: media.name } }">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>

          <v-btn icon color="error" @click="deleteMedia(media)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </nobr>
      </template>
    </data-table>

    <v-divider class="my-6" />

    <div class="text-h6 mb-3">Новое СМИ</div>

    <create-media-form />
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import CreateMediaForm from "~/components/media/create-media-form";

export default {
  data() {
    return {
      headers: [
        { text: "Лого", value: "logo", sortable: false },
        { text: "Название", value: "name", sortable: false },
        { text: "RSS", value: "rss", sortable: false },
        { text: "Действие", value: "actions", sortable: false }
      ],

      imgKey: 0
    };
  },
  methods: {
    refreshTable() {
      return this.$refs.dataTable.update();
    },
    async deleteMedia(media) {
      try {
        await this.$axios.$delete(`news/rss/${media.name}`);

        await this.$refs.dataTable.update();

        this.$snackbar.success("Сми удалена");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не удалена СМИ" + html);
      }
    }
  },
  components: {
    CreateMediaForm
  }
};
</script>
