<template>
  <div>
    <v-card class="py-2" :class="{ 'grey darken-1': dark }" elevation="3" :dark="dark">
      <v-card-title>
        <div>Логотип</div>
      </v-card-title>

      <v-card-text>
        <v-img class="rounded-circle" :src="`${media.logo}?${imgKey}`" width="88" height="88" contain />

        <v-file-input v-model="logo" label="Выбрать файл" prepend-icon="mdi-camera" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    media: {
      type: Object,
      required: true
    },
    value: {
      default: null,
      validator() {
        return true;
      }
    }
  },
  data() {
    return {
      dark: true,

      imgKey: 0
    };
  },
  computed: {
    logo: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      }
    }
  },
  watch: {
    logo(newVal) {
      if (newVal === null) this.imgKey += 1;
    }
  }
};
</script>
